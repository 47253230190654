import config from "../config";
import axios from "axios";
import { clearToken } from "./utility";
import { Map } from "immutable";

const API_URL = config.apiUrl;

export const customerLogout = async () => {
    const currentUser = await getCurrentUser();
    console.log("customerLogout currentUser", currentUser);
    if (!currentUser) {
        return false;
    }
    const { objectId: userId, sessionToken } = currentUser;
    const userRes = await (await axios.post(`${API_URL}/user/logout`, { userId, sessionToken })).data;
    if (userRes.status === "success") {
        clearToken();
        return true;
    } else {
        return false;
    }
};

export const login = async (userInfo) => {
    if (!userInfo.username || !userInfo.password) {
        throw {
            message: "ใส่ข้อมูลให้ครบ",
        };
    }
    const userRes = await (await axios.post(`${API_URL}/user/login`, { username: userInfo.username.toLowerCase(), password: userInfo.password })).data;
    if (userRes.status === "success") {
        return userRes.user;
    } else {
        throw { message: userRes.error };
    }
};

export const getConfig = async () => {
    const config = await (await axios.get(`${API_URL}/config`)).data;
    return new Map({ ...config.data });
};

export const getCurrentUser = async () => {
    const data = localStorage.getItem("currentUser");
    if (data) {
        const user = JSON.parse(data);
        const userBalance = await (await axios.get(`${API_URL}/user/balance?userId=${user.id}`)).data;
        const { data: balance, creditPoint, currentTurnover } = userBalance;
        return { ...user, balance, creditPoint, currentTurnover };
    } else {
        return null;
    }
};

export const getUserTransaction = async (userId) => {
    const wList = await (await axios.get(`${API_URL}/withdraw/list?userId=${userId}`)).data;
    const { data: w } = wList;
    const dList = await (await axios.get(`${API_URL}/deposit/list?userId=${userId}`)).data;
    const { data: d } = dList;

    const t = d
        .map((item) => {
            item.className = "Deposit";
            return item;
        })
        .concat(
            w.map((item) => {
                item.className = "Withdraw";
                return item;
            })
        );

    const mapped = t.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1));
    return mapped.length >= 10 ? mapped.slice(0, 10) : mapped;
};

export const getUserRebates = async (userId) => {
    const list = await (await axios.get(`${API_URL}/rebate/list?username=${userId}`)).data;
    const { data: rebates } = list;
    const mapped = rebates.map((item) => {
        item.className = "Rebate";
        return item;
    });
    return mapped;
};

export const getUserReferralBonus = async (userId) => {
    const list = await (await axios.get(`${API_URL}/referral/list?username=${userId}`)).data;
    const { data: rebates } = list;
    const mapped = rebates.map((item) => {
        item.className = "Rebate";
        return item;
    });
    return mapped;
};

export const getDownlines = async (userId) => {
    const list = await (await axios.get(`${API_URL}/user/downlines?parent=${userId}`)).data;
    const { data } = list;
    return data;
};
