const env = {}
const PROD = true
const URL = PROD?"https://api.pgslot99.link":'http://localhost'
env.url = URL
env.port = P_PORT;
env.apiUrl = `${URL}:${P_PORT}/api/v1`;
env.parseServerUrl = `${URL}:${P_PORT}/parse`;
env.lineURL = 'https://lin.ee/xEgi7hE'
env.lineName = '@psy888th'
env.appName = 'PUSSY888'
env.version = "1.2.1"
export default env