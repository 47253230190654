import { all, takeEvery, put, call, fork } from "redux-saga/effects";
import { push } from "react-router-redux";
import { getToken, clearToken } from "../../helpers/utility";
import actions from "./actions";
import { notification } from "../../components";
import { login } from "../../helpers/parseHelper";
import Swal from "sweetalert2";

export function* loginRequest() {
    yield takeEvery(actions.LOGIN_REQUEST, function* ({ payload }) {
        try {
            const result = yield call(login, payload.userInfo);
            //console.log(`result  sessionToken : ${result.get('sessionToken')} | objectId : ${result.id}  | expireTime : ${result.get('expireTime')}`)
            yield put({
                type: actions.LOGIN_SUCCESS,
                token: result.sessionToken,
                expireTime: result.expireTime,
                currentUser: JSON.stringify(result),
            });
            yield put(push("/"));
        } catch (error) {
            //notification("error", error.message);
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: error.message,
            });
            yield put({
                type: actions.LOGIN_ERROR,
            });
        }
    });
}

export function* jwtLoginRequest() {
    yield takeEvery(actions.JWT_LOGIN_REQUEST, function* ({ payload }) {
        try {
            const result = yield call(login, payload.userInfo);
            //console.log(`result  sessionToken : ${result.get('sessionToken')} | objectId : ${result.id}  | expireTime : ${result.get('expireTime')}`)
            yield put({
                type: actions.LOGIN_SUCCESS,
                token: result.sessionToken,
                expireTime: result.expireTime,
            });
            yield put(push("/admin"));
        } catch (error) {
            notification("error", error.message);
            yield put({
                type: actions.LOGIN_ERROR,
            });
        }
    });
}

export function* loginSuccess() {
    yield takeEvery(actions.LOGIN_SUCCESS, function* (payload) {
        yield localStorage.setItem("token", payload.token);
        yield localStorage.setItem("expireTime", payload.expireTime);
        yield localStorage.setItem("currentUser", payload.currentUser);
    });
}

export function* loginError() {
    yield takeEvery(actions.LOGIN_ERROR, function* () {});
}

export function* logout() {
    yield takeEvery(actions.LOGOUT, function* () {
        clearToken();
        yield put(push("/login"));
    });
}
export function* checkAuthorization() {
    yield takeEvery(actions.CHECK_AUTHORIZATION, function* () {
        const token = getToken().get("token");
        const expireTime = getToken().get("expireTime");
        const currentUser = getToken().get("currentUser");
        const currentTimestamp = Math.round(new Date().getTime() / 1000);
        if (token && expireTime > currentTimestamp) {
            yield put({
                type: actions.LOGIN_SUCCESS,
                token,
                expireTime,
                currentUser,
            });
        }
    });
}
export default function* rootSaga() {
    yield all([fork(checkAuthorization), fork(jwtLoginRequest), fork(loginRequest), fork(loginSuccess), fork(loginError), fork(logout)]);
}
