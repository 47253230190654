import React, { Component } from "react";
import { connect } from "react-redux";
import { Layout } from "antd";
import appActions from "../../redux/app/actions";
import TopbarLogout from "./topbarLogout";
import TopbarWrapper from "./topbar.customer.style";
import themes from "../../settings/themes";
import { themeConfig } from "../../settings";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
const { Header } = Layout;
const { toggleCollapsed } = appActions;
const customizedTheme = themes[themeConfig.theme];

class Topbar extends Component {
  render() {
    const { toggleCollapsed,username } = this.props;
    const collapsed = this.props.collapsed && !this.props.openDrawer;
    const styling = {
      background: customizedTheme.backgroundColor,
      position: "fixed",
      width: "100%",
      height: 70
    };
    return (
      <TopbarWrapper style={{backgroundColor:'#040506'}}>
        <Header
          style={styling}
          className={
            collapsed ? "isomorphicTopbar collapsed" : "isomorphicTopbar"
          }
        >
          <div className="isoLeft">
            <button
              className={
                collapsed ? "triggerBtn menuCollapsed" : "triggerBtn menuOpen"
              }
              style={{ color: 'white' }}
              onClick={toggleCollapsed}
            >
            <span className="isoTimer" style={{color:'#f9d276',marginTop:0,marginLeft:56}}>{`เมนู`}</span>
            </button>
            {/* <p className="isoTimer" style={{color:'#f9d276',marginTop:16,marginLeft:8}}>{`เหลือเวลา:${timer||'00:00:00'}`}</p> */}
          </div>

          <ul className="isoRight">
            <FontAwesomeIcon icon={faUser} size="lg" style={{color:'#f9d276',marginTop:16}}/>
            <p className="isoTimer" style={{color:'#f9d276',marginTop:34,marginLeft:8,marginRight:12}}>{`${username||''}`}</p>
            <Link to="/gameList" style={{marginTop:18}}>
            <li className="isoHome" style={{color: '#f9d276',fontWeight: 'bold',border:'1px solid #f9d276',padding:'5px 12px',marginRight:5}}>
              {`หน้าแรก`}
            </li>
            </Link>
            <li
              onClick={() => this.setState({ selectedItem: "user" })}
              className="isoUser"
            >
              <TopbarLogout />
            </li>
          </ul>
        </Header>
      </TopbarWrapper>
    );
  }
}

export default connect(
  state => ({
    ...state.App
  }),
  { toggleCollapsed }
)(Topbar);
