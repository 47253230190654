import React from "react";
import { Route, Redirect } from "react-router-dom";
import { ConnectedRouter } from "react-router-redux";
import { connect } from "react-redux";

import CustomerApp from "./containers/CustomerApp/App";
import asyncComponent from "./helpers/AsyncFunc";

const RestrictedAdminRoute = ({ component: Component, isLoggedIn, ...rest }) => (
    <Route
        {...rest}
        render={(props) =>
            isLoggedIn ? (
                <Component {...props} />
            ) : (
                <Redirect
                    to={{
                        pathname: "/admin-authen",
                        state: { from: props.location },
                    }}
                />
            )
        }
    />
);
const RestrictedRoute = ({ component: Component, isLoggedIn, ...rest }) => (
    <Route
        {...rest}
        render={(props) =>
            isLoggedIn ? (
                <Component {...props} />
            ) : (
                <Redirect
                    to={{
                        pathname: "/login",
                        state: { from: props.location },
                    }}
                />
            )
        }
    />
);

const PublicRoutes = ({ history, isLoggedIn }) => {
    return (
        <ConnectedRouter history={history}>
            <div>
                <RestrictedRoute exact path={"/"} isLoggedIn={isLoggedIn} component={asyncComponent(() => import("./containers/CustomerHome"))} />
                <RestrictedRoute exact path={"/depositMenu"} isLoggedIn={isLoggedIn} component={asyncComponent(() => import("./containers/DepositMenu"))} />
                <RestrictedRoute exact path={"/depositMenu/deposit"} isLoggedIn={isLoggedIn} component={asyncComponent(() => import("./containers/DepositMenu/auto"))} />
                <RestrictedRoute exact path={"/withdraw"} isLoggedIn={isLoggedIn} component={asyncComponent(() => import("./containers/Withdraw"))} />
                <RestrictedRoute exact path={"/gameList"} isLoggedIn={isLoggedIn} component={asyncComponent(() => import("./containers/CustomerGameList"))} />
                <RestrictedRoute exact path={"/lobby/:GAME_NAME"} isLoggedIn={isLoggedIn} component={asyncComponent(() => import("./containers/Lobby"))} />
                {/* <RestrictedRoute
          exact
          path={'/lobby/H918Kiss'}
          isLoggedIn={isLoggedIn}
          component={asyncComponent(() => import('./containers/Lobby/918'))}
        />
        <RestrictedRoute
          exact
          path={'/lobby/PUSSY888'}
          isLoggedIn={isLoggedIn}
          component={asyncComponent(() => import('./containers/Lobby/pussy'))}
        />
        <RestrictedRoute
          exact
          path={'/lobby/PGSLOT'}
          isLoggedIn={isLoggedIn}
          component={asyncComponent(() => import('./containers/Lobby/pgslot'))}
        />
        <RestrictedRoute
          exact
          path={'/lobby/JOKER'}
          isLoggedIn={isLoggedIn}
          component={asyncComponent(() => import('./containers/Lobby/joker'))}
        />
        <RestrictedRoute
          exact
          path={'/lobby/SAGAMING'}
          isLoggedIn={isLoggedIn}
          component={asyncComponent(() => import('./containers/Lobby/sagaming'))}
        />
        <RestrictedRoute
          exact
          path={'/lobby/SEXYBACCARAT'}
          isLoggedIn={isLoggedIn}
          component={asyncComponent(() => import('./containers/Lobby/sexy'))}
        /> */}
                <RestrictedRoute exact path={"/paymentHistory"} isLoggedIn={isLoggedIn} component={asyncComponent(() => import("./containers/PaymentHistory"))} />
                <RestrictedRoute exact path={"/rebate"} isLoggedIn={isLoggedIn} component={asyncComponent(() => import("./containers/CustomerRebate"))} />
                <RestrictedRoute exact path={"/profile"} isLoggedIn={isLoggedIn} component={asyncComponent(() => import("./containers/CustomerProfile"))} />
                <RestrictedRoute exact path={"/resetPassword"} isLoggedIn={isLoggedIn} component={asyncComponent(() => import("./containers/CustomerPassword"))} />
                <RestrictedRoute exact path={"/editBankInfo"} isLoggedIn={isLoggedIn} component={asyncComponent(() => import("./containers/EditBankInfo"))} />
                <RestrictedRoute exact path={"/promotion"} isLoggedIn={isLoggedIn} component={asyncComponent(() => import("./containers/Promotion"))} />
                <RestrictedRoute exact path={"/referral"} isLoggedIn={isLoggedIn} component={asyncComponent(() => import("./containers/Referral"))} />
                <RestrictedRoute exact path={"/referralBonus"} isLoggedIn={isLoggedIn} component={asyncComponent(() => import("./containers/ReferralBonus"))} />
                <Route exact path={"/login"} component={asyncComponent(() => import("./containers/CustomerAuthen"))} />
                <Route exact path={"/register"} component={asyncComponent(() => import("./containers/CustomerRigister"))} />
                <RestrictedAdminRoute path="/admin" component={CustomerApp} isLoggedIn={isLoggedIn} />
                <Route exact path={"/check"} component={asyncComponent(() => import("./containers/blankPage"))} />
            </div>
        </ConnectedRouter>
    );
};

export default connect((state) => ({
    isLoggedIn: state.Auth.idToken !== null,
}))(PublicRoutes);
