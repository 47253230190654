import React, { Component } from "react";
import { connect } from "react-redux";
import { Layout } from "antd";
import { Debounce } from "react-throttle";
import WindowResizeListener from "react-window-size-listener";
import { ThemeProvider } from "styled-components";
import authAction from "../../redux/auth/actions";
import appActions from "../../redux/app/actions";
import Sidebar from "../CustomerSidebar/Sidebar";
import Topbar from "../Topbar/TopbarCustomer";
import AppRouter from "./AppRouter";
import { siteConfig } from "../../settings";
import themes from "../../settings/themes";
import { themeConfig } from "../../settings";
import AppHolder from "./commonStyle";
import { getCurrentUser } from "../../helpers/parseHelper";
import "./global.css";

const { Content, Footer } = Layout;
const { logout } = authAction;
const { toggleAll } = appActions;
export class App extends Component {
    state = {
        currentUser: null,
    };
    componentDidMount = async () => {
        //console.log('YO');
        const currentUser = await getCurrentUser();
        this.setState({ currentUser });
    };
    render() {
        const { url } = this.props.match;
        const { height } = this.props;
        const appHeight = window.innerHeight;
        const { currentUser } = this.state;
        return (
            <ThemeProvider theme={themes[themeConfig.theme]}>
                <AppHolder>
                    <Layout style={{ height: appHeight }}>
                        <Debounce time="1000" handler="onResize">
                            <WindowResizeListener onResize={(windowSize) => this.props.toggleAll(windowSize.windowWidth, windowSize.windowHeight)} />
                        </Debounce>
                        <Topbar url={url} username={currentUser ? currentUser.username : null} />
                        <Layout style={{ flexDirection: "row", overflowX: "hidden" }}>
                            <Sidebar url={url} history={this.props.history} />
                            <Layout
                                className="isoContentMainLayout"
                                style={{
                                    height: height,
                                }}
                            >
                                <Content
                                    className="isomorphicContent"
                                    style={{
                                        padding: "70px 0 0",
                                        flexShrink: "0",
                                        background: "#f1f3f6",
                                        position: "relative",
                                    }}
                                >
                                    <AppRouter url={url} user={currentUser} />
                                </Content>
                            </Layout>
                        </Layout>
                    </Layout>
                </AppHolder>
            </ThemeProvider>
        );
    }
}

export default connect(
    (state) => ({
        auth: state.Auth,
        height: state.App.height,
    }),
    { logout, toggleAll }
)(App);
