const options = [
    {
        key: '1',
        label: 'สูตรที่ 1',
    }, {
        key: '2',
        label: 'สูตรที่ 2',
    }, {
        key: '3',
        label: 'สูตรที่ 3',
    }, {
        key: '4',
        label: 'สูตรที่ 4',
    }, {
        key: '5',
        label: 'สูตรที่ 5',
    },

];
export default options;
